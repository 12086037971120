import message from '@/components/message/'
import confirm from '@/components/confirm/'
import Vue from 'vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css';
// import 'viewerjs/dist/viewer.css'
// import VueViewer from 'v-viewer'
Vue.use(VueAwesomeSwiper)
// Vue.use(VueViewer)
Vue.use(message)
Vue.use(confirm)
