<template>
	<div class="m-home-header-main" :class="{ productHeader, opacityHeader, showNav }">
		<div class="back_btn"  @click="onBack"><img src="@/assets/index/header/back_icon.png" alt="" class="back_btn_icon"></div>
		<div class="home-header-left"> 
			<div class="nav_icon_box">
				<div class="nav_icon" @click="showNav = !showNav">
					<div class="nav_icon_line"></div>
					<div class="nav_icon_line"></div>
					<div class="nav_icon_line"></div>
				</div>
			</div>
			<div class="nav_list">
				<div class="nav_item" :class="{mobileNav: item.isMobile}" v-for="(item, index) in navs" :ref="`navItem_${item.pageName}`" :key="index" >
					<div class="nav_text" :class="{ active: current === item.pageName }" @click="onRouter(item)">{{ item.text }}</div>
					<img src="@/assets/index/header/nav_more.png" v-if="item.child" alt="" class="child-more-icon" @click="onShowNavChild">
					<!-- <transition name="nav-child"> -->
						<div class="nav_child_list" v-if="item.child&&showNavChild">
							<div class="nav_child_item" v-for="child in item.child" @click="onRouter(child)">{{ child.text }}</div>
						</div>
					<!-- </transition> -->
				</div>
				<div class="nav_bar" v-if="current" :style="barStyle"></div>
			</div>
		</div>
		<div class="header-logo"  @click="onLink('/')">
			<img src="@/assets/logo.png" alt="" class="header-logo-img">
		</div>
		<headSearch class="header_search" v-if="(!hideSearch&&!productHeader) || isMobile" @search="onSearch"></headSearch>
		<div class="home-header-right">
			<template v-if="showRightNav">
				<div class="product_nav_list">
					<router-link to="/product/index" class="product_nav_item" :class="{ active: pageName === 'productIndex' }">解决方案</router-link>
					<router-link to="/product/example" class="product_nav_item" :class="{ active: pageName === 'example' }">成功案例</router-link>
					<router-link to="/serve" class="product_nav_item" v-if="!isMobile" :class="{ active: pageName === 'serve' }">联系我们</router-link>
					<router-link to="/product/aboutAs" class="product_nav_item" v-if="!isMobile" :class="{ active: pageName === 'aboutAs' }">关于我们</router-link>
				</div>
				<div class="header-btn more" @click="onLink('/plan')">新订阅限时￥98 <img src="@/assets/index/header/more.png" alt="" class="more_icon" ></div>
				<div class="header-btn" @click="onLink('/download')">下载桌面端</div>
			</template>
			
			<!-- <div class="languages-clock">
				<span class="action">EN</span> | <span>CN</span>
			</div> -->
			<img src="@/assets/index/header/star.png" alt="" class="coll-btn-icon" @click="onLink('/mogine/coll/like')">
			<div class="header-image-box" @click.stop>
				<img v-if="user.loginStatus" :src="user.headImage" alt="" class="header-headerImage" @click.stop="onShowUserInfo">
				<div class="default_userHeader" v-else @click="onLogin">登录</div>
				<div class="red-point"></div>
				<userInfo v-model="showUserInfo"  ></userInfo>
			</div>
		</div>
	</div>
</template>

<script>
	import { productList, resoures, works, mogineUrl } from '../navOptions.js'
	import { User } from '@/model/user.js'
	import headSearch from '@/components/index/search.vue'
	import { mapMutations } from 'vuex'
	import userInfo from './userInfo.vue'
	
	import pointVip from '@/views/pay/point_vip.vue'
	
	export default {
		name: 'homeheader',
		components: {
			headSearch, userInfo, pointVip
		},
		props: {
		},
		data: ( ) => {
			return {
				current: null,
				showNavChild: false,	
				showUserInfo: false,
				navs: [
					// { text: '产品',   pageName: 'product', path: '/product/index', child: productList },
					{ text: '产品',   pageName: 'index', path: '/index', child: productList },
					{ text: '摩力站', pageName: 'home',  path: '/wecome', isMobile: true },
					{ text: '资源库', pageName: 'mogine',  path: '/mogine/index' },
					{ text: 'MOGINE 3D', pageName: 'download',  path: '/download', isMobile: true  },
					{ text: '数字人', pageName: 'aiHuman',  path: '/digitalHuman', isMobile: true  },
					{ text: '工作流', pageName: 'works',   path: '/works' },
					// { text: '企业服务', pageName: 'serve', path: '/serve' },
					{ text: '企业服务', pageName: 'product', path: '/product/index' },
					{ text: '新闻媒体', pageName: 'news', path: '/news' }
				],
				barStyle: '',
				defaultHeadImage: User.defaultHeadImage,
				showNav: false
			}
		},
		computed: {
			hideSearch() {
				return this.$route.matched.some(item => {
					return item.meta.hideSearch 
				})
			},
			showRightNav() {
				let flag = true
				this.$route.matched.find(item => {
					if (item.meta.showRightNav !== undefined && !item.meta.showRightNav) flag = false
				})
				return flag
			},
			productHeader() {
				return this.$route.matched.some(item => {
					return item.meta.productHeader 
				})
			},
			opacityHeader() {
				return this.$route.matched.some(item => {
					return item.meta.opacityHeader 
				})
			},
			pageName() {
				return this.$route.name
			}
		},
		mounted() {
			this.onCurrentChange()
			window.addEventListener('resize', this.navBarChange.bind(this))
		},
		methods: {
			...mapMutations(['targetLoginVisible']),
			onBack() {
				const routes = this.$router.history;
				this.$router.back()
				// this.$router.push('/')
			},
			onSearch(searchKey) {
				this.$router.push({ path:'/mogine/index', query: { searchKey } } )
			},
			onCurrentChange(){
				let cur = null
				this.navs.forEach(({ pageName, isMobile }) => {
					if (this.$route.matched.find(item => item.name === pageName && !isMobile)) {
						cur = pageName
					}
				})
				this.current = cur
				this.navBarChange()
			},
			navBarChange() {
				if (this.$refs[`navItem_${this.current}`]) {
					let dom = this.$refs[`navItem_${this.current}`][0]
					let left = dom.offsetLeft
					let width = dom.offsetWidth
					this.barStyle = `left:${left + width/2 - 12}px`
				}
			},
			onShowNavChild(){
				let flag = !this.showNavChild
				this.showNavChild = flag
				console.log(flag)
				if (flag) {
					let setFalseFn = () => {
						this.showNavChild = false
						document.body.removeEventListener('click',setFalseFn)
					}
						
					let timer = setTimeout(() => {
						document.body.addEventListener('click',setFalseFn)
						clearTimeout(timer)
						timer = null
					},100)
					
				}
				
			},
			onShowUserInfo() {
				console.log(123)
				this.showUserInfo = !this.showUserInfo
				let closeFn = () => {
					this.showUserInfo = false
					document.body.removeEventListener('click', closeFn)
				}
				this.$nextTick(() => {
					document.body.addEventListener('click', closeFn)
				})
				
			},
			onLogin() {
				let { loginStatus } = this.user
				if (!loginStatus) this.targetLoginVisible(true)
			},
			onLogout() {
				if (window.confirm('是否退出')) {
					this.user.logout()
					this.$message.success('注销成功')
					this.$router.push('/')
				}
			},
			onRouter(nav) {
				if (nav.path) {
					this.$router.push({ path: nav.path })
				}
				this.showNav = false
			},
			onNavChildClick() {
				
			}
		},
		watch: {
			$route() {
				this.$nextTick(() => {
					this.onCurrentChange()
				})
				
			}
		}
	}
</script>

<style>
	.nav-child-enter-active,  {
		transition: all 1s;
	}
	.nav-child-leave-active{
		transition: all 1s;
	}
	
	.nav-child-enter, .nav-child-leave-to /* .fade-leave-active below version 2.1.8 */ {
	  opacity: 0;
	  transform: translateX(50px);
	}
	.nav-child-enter-to{
	  /* transform: translateX(0) */
	}
</style>

<style scoped lang="less">
	@keyframes navChildEnter {
		0%{
			transform: translateX(100px);
			opacity: 0;
		}
		100%{
			transform: translateX(0px);
			opacity: 1;
		}
	}
	
	.m-home-header-main{
		display: flex;
		top: 0;
		left: 0;      
		width:100%;
		height: 80px;
		background: #000;
		position: fixed;
		align-items: center;
		justify-content: space-between;
		padding-left: 43px;
		padding-right: 24px;
		z-index: 9999;
		&.productHeader{
			.nav_list,.header-btn{
				display: none;
			}
			.header-logo{
				left: 120px;
			}
			.back_btn{
				display: flex;
			}
			.product_nav_list{
				display: flex;
			}
			
		}
		&.opacityHeader{
			background-color: transparent;
			.back_btn{
				// display: none;
				background-color: transparent;
			}
		}
		.home-header-left,.home-header-right{
			display: flex;
			align-items: center;
			flex: 0 0 auto;
		}
		.home-header-left{
			padding-left: 1.84rem;
			.nav_icon_box{
				display: none;
			}
		}
		.back_btn{
			width: .40rem;
			height: .40rem;
			display: flex;
			align-items: center;
			justify-content: center;
			background: #161718;
			border-radius: 8px;
			cursor: pointer;
			margin-right: 40px;
			user-select: none;
			display: none;
			.back_btn_icon{
				width: 10px;
			}
		}
		.product_nav_list{
			display: flex;
			align-items: center;
			margin-right: 80px;
			display: none;
			.product_nav_item{
				font-size: 16px;
				cursor: pointer;
				line-height: 24px;
				color: rgba(255,255,255,.6);
				margin: 0 35px;
				&.active{
					color: #fff;
				}
			}
		}
		.header-logo{
			width: 1.58rem;
			// height: 43px;
			margin-right: 20px;
			cursor: pointer;
			position: absolute;
			left: 45px;
			top: 50%;
			transform: translateY(-50%);
			overflow: hidden;
			.header-logo-img{
				width: 100%;
				line-height: 1;
				display: block;
			}
		}
		.nav_list{
			display: flex;
			position: relative;
			.nav_item{
				padding: 0 20px;
				position: relative;
				&.mobileNav{
					display: none;
				}
				.nav_text{
					font-size: 20px;
					line-height: 28px;
					color: #abaeb4;
					font-family: PingFang SC '微软雅黑';
					cursor: pointer;
					&.active{
						color: rgba(245,245,245, 1);
					}
				}
				.child-more-icon{
					cursor: pointer;
					width: 13px;
					position: absolute;
					right: 3px;
					top:11px;
				}
				.nav_child_list{
					position: absolute;
					background: rgba(22, 23, 24, 1);
					width: 240px;
					top: 54px;
					animation: navChildEnter .3s;
					.nav_child_item{
						font-size: 16px;
						color: #fff;
						text-align: center;
						line-height: 60px;
						cursor: pointer;
						user-select: none;
						&:hover{
							background: rgba(44,44,44,1);
						}
					}
				}
			}
			.nav_bar{
				width: 24px;
				height: 3px;
				position: absolute;
				bottom: -8px;
				background-image: @theme3-color;
				transition: left .5s;
			}
		}
		.header-btn{
			height: 40px;
			line-height: 40px;
			border: 1px solid rgba(119, 124, 132, 1);
			text-align: center;
			color: #fff;
			padding: 0 20px;
			font-size: 14px;
			border-radius: 20px;
			margin-right: 16px;
			cursor: pointer;
			background-color: #000;
			user-select: none;
			position: relative;
			&.more{
				padding-right: 30px;
				.theme3-border(2px);
				// background: @theme3-color;
				.more_icon{
					width: 20px;
					height: 20px;
					position: absolute;
					right: 5px;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}
		.languages-clock{
			font-size: 14px;
			color: rgba(255,255,255,.5);
			cursor: pointer;
			margin-right: 16px;
			.action{
				color: #fff;
			}
		}
		.coll-btn-icon{
			width: 32px;
			height: 32px;
			margin-right: 16px;
			cursor: pointer;
		}
		.header-image-box{
			height: 32px;
			position: relative;
			font-size: 12px;
			color: #fff;
			border-radius: 16px;
			
			vertical-align: middle;
			.header-headerImage{
				width: 32px;
				height: 32px;
				border-radius: 16px;
				cursor: pointer;
			}
			.default_userHeader{
				// width: 32px;
				// height: 32px;
				// background-image: @theme3-color;
				// border-radius: 16px;
				text-align: center;
				line-height: 32px;
				cursor: pointer;
				font-size: 20px;
				color: #fff;
			}
		}
		.header_search{
			max-width: 732px;
			flex: 1 1 auto;
			margin-right: 12px;
		}
	}
</style>

<style scoped lang="less">
	@media screen and (max-width: 1440px) {
		.m-home-header-main{
		}
	}
</style>

<style scoped lang="less">
	@media screen and (max-width: 760px) {
		.m-home-header-main{
			width: 100%;
			max-width: 100%;
			height: 60px;
			padding: 0 20px;
			.header-logo{
				width: 102px;
				height: auto;
				left: 50%;
				transform: translate(-50%, -50%);
				z-index: 110;
			}
			.home-header-left{
				padding-left: 0;
				.nav_icon_box{
					width: 21px;
					height: 19px;
					display: block;
					cursor: pointer;
					.nav_icon{
						width: 21px;
						height: 19px;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						.nav_icon_line{
							width: 21px;
							height: 3px;
							border-radius: 1.5px;
							background-color: #fff;
						}
					}
				}
				.nav_list{
					display: none;
				}
			}
			.header_search{
				flex: 0 0 auto;
				
			}
			.home-header-right{
				display: none;
				.header-image-box,.coll-btn-icon,.header-btn{
					
				}
			}
			&.showNav{
				.home-header-left{
					position: fixed;
					width: 100vw;
					height: 100vh;
					background-color: rgba(0,0,0,.8);
					top: 0;
					left: 0;
					z-index: 100;
					.nav_icon_box{
						
						.nav_icon{
							position: absolute;
							top: 21px;
							left: 20px;
							z-index: 102;
							.nav_icon_line{
								transition: opacity .6s;
								&:first-child{
									opacity: 0;
								}
								&:last-child{
									opacity: 0;
								}
							}
						}
					}
				}
				.nav_list{
					position: fixed;
					width: 100vw;
					height: 100vh;
					top: 0;
					left: 0;
					z-index: 101;
					flex-direction: column;
					display: flex;
					padding-top: 80px;
					.nav_item{
						width: 100%;
						margin-bottom: 42px;
						&.mobileNav{
							display: block;
						}
						.nav_text{
							color:#fff;
							padding: 0 20px;
							font-size: 16px;
						}
						.child-more-icon{
							display: none;
						}
					}
				}
			}
			&.productHeader{
				justify-content: space-between;
				padding-left: 20px;
				.back_btn,.header-logo{
					display: none;
				}
				.home-header-left{
				}
				.home-header-right{
					display: flex;
					justify-content: flex-end;
					.header-btn,.coll-btn-icon,.languages-clock,.header-image-box{
						display: none;
					}      
					.product_nav_list{
						margin-right: 0;
					}
				}
				.header_search{
					display: none;
				}
			}
		}
	}
</style>